

































































































































































.merge-fields-group-wrapper {
  .mergefield-dropdown,
  .mergefield-dropdown ul,
  .mergefield-dropdown li,
  .mergefield-dropdown a {
    margin: 0;
    padding: 0;
    line-height: normal;
    list-style: none;
    display: block;
    position: relative;
  }
  .mergefield-dropdown {
    display: inline-block;
  }

  .mergefield-dropdown ul {
    opacity: 0;
    position: absolute;
    top: 100%;
    left: -9999px;
    z-index: 999;
    -webkit-transition: opacity .3s;
    transition: opacity .3s;
  }

  .mergefield-dropdown-element.open>ul {
    left: 0;
    opacity: 1;
    z-index: 1000;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, .15);
    background: #fff;
    animation-name: DropDownSlide;
    animation-duration: .3s;
    animation-fill-mode: both;
  }

  .mergefield-dropdown li:hover>ul {
    left: 0;
    opacity: 1;
    z-index: 1000;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, .15);
    animation-name: DropDownSlide;
    animation-duration: .3s;
    animation-fill-mode: both;
  }

  .mergefield-dropdown ul li:hover>ul {
    top: 0;
    left: 100%;
  }

  .mergefield-dropdown li {
    cursor: default;
    float: left;
    white-space: nowrap;
  }

  .mergefield-dropdown ul li {
    float: none;
  }

  /* sub width */
  .mergefield-dropdown ul {
    min-width: 12em;
    -webkit-box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.25);
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.25);
  }

  /* root */
  .mergefield-dropdown a {
    padding: 0 10px;
    color: #333;
    font-weight: normal;
    font-size: 16px;
    line-height: 40px;
    text-decoration: none;
  }

  /* root: hover/persistence */
  .mergefield-dropdown a:hover,
  .mergefield-dropdown a:focus,
  .mergefield-dropdown li:hover a {
    background: #fff;
    color: #333;
  }

  /* 2 */
  .dropdown-item a,
  .mergefield-dropdown li li a,
  .mergefield-dropdown li:hover li a {
    padding: 10px 10px;
    background: #fff;
    color: #333;
    font-size: 14px;
    line-height: normal;
    display: flex;
    justify-content: flex-start;
  }

  .dropdown-item a {
    justify-content: space-between;
  }

  /* 2: hover/persistence */
  .mergefield-dropdown li:hover li a:hover,
  .mergefield-dropdown li:hover li a:focus,
  .mergefield-dropdown li:hover li:hover a {
    background: #efefef;
  }
}
